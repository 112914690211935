
import Vue from 'vue';
export default Vue.extend({
  metaInfo: {
    title: 'Employees - '
  },
  name: 'Employees',
  components: {},
  data: () => ({}),
  computed: {}
});
